import { SUBSCRIPTION_ALERT } from "../../schema/alertRules";

export default {
    namespaced: true,

    state: {
        alerts: [],
    },

    getters: {
        getTriggeringAlerts: (state) => {
            return state.alerts.filter(
                (item) => item.stopped_at === null || item.stopped_at === ''
            );
        },
        getAlertsByDevice: (state) => (device_id) => {
            return state.alerts.filter((item) => item.device_id === device_id)
        },
        getAlertsByRule: (state) => (rule_id) => {
            return state.alerts.filter(
                (item) => 
                    item.rule_id === rule_id && 
                    (item.stopped_at === null || item.stopped_at === '')
            )
        }
    },

    mutations: {
        clear: (state) => {
            state.alerts = [];
        },
        updateAlert: (state, item) => {
            const index = state.alerts.findIndex((a) => a.id === item.id);

            if (index !== -1) {
                state.alerts[index].stopped_at = item.stopped_at
                
                let newConds = item.conditions_triggering.filter(
                    (condition) => !condition.is_triggering
                )

                state.alerts[index].conditions_triggering = [...newConds, ... state.alerts[index].conditions_triggering]
            } else {
                state.alerts.unshift(item);
            }
        }
    },

    actions: {
        subscribe: ({ commit, rootGetters }, payload) => {
            commit('clear');

            rootGetters.apollo
                .subscribe({
                    query: SUBSCRIPTION_ALERT,
                    variables: {
                        projectId: payload.project_id,
                    },
                })
                .subscribe({
                    async next(message) {
                        commit('updateAlert', message.data.liveAlerts);
                    },
                    error(error) {
                        console.error('error', error);
                    },
                });
        },
    },
};
