<template>
    <article class="message is-darker" v-if="rowData">
        <div class="card-content">
            <vuetable 
                ref="vuetable" 
                class="is-fullwidth" 
                :api-mode="false" 
                :data="rowData.conditions_triggering" 
                :fields="fields"
            >
                <div slot="device_condition" slot-scope="props" class="is-flex">
                    <p class="mr-3">{{ props.rowData.state_key}} </p>
                    <p class="mr-3">{{ props.rowData.operator }} </p>
                    <p >
                        {{ props.rowData.threshold_value }}
                    </p>
                </div>
                <div slot="device_value" slot-scope="props" class="is-flex">
                    <p :class="{
                            'has-text-danger': props.rowData.is_triggering,
                            'has-text-success': !props.rowData.is_triggering,
                        }">
                        {{ props.rowData.state_value }}
                    </p>
                </div>
            </vuetable>
        </div>
    </article>
</template>

<script>

export default {
    name: 'device-alert-detail',
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        },
        options: {
            type: Object,
        }
    },
    data: () => {
        return {
            is_visible: false,
            fields: [
                {
                    name: '__slot:device_condition',
                    title: 'Condition',
                    titleClass: 'center aligned',
                    dataClass: 'center aligned'
                },
                {
                    name: '__slot:device_value',
                    title: 'Value',
                    titleClass: 'center aligned',
                    dataClass: 'center aligned'
                },
                { name: 'threshold_hysteresis', title: 'Hysteresis' }, 
                { 
                    name: 'created_at', 
                    title: 'Created At', 
                    callback(value) {
                        if (!value) return 'n/a';

                        return (new Date(value)).toLocaleString();
                    } 
                }, 
            ]
        };
    },
};

</script>