import {
    STORE_ALERT_RULE,
    UPDATE_ALERT_RULE,
    DELETE_ALERT_RULE,
    VIEW_NOTIFIABLE,
    SUBSCRIPTION_ALERT_RULE,
} from '../../schema/alertRules';

export default {
    namespaced: true,
    state: {
        alert_rules: [],
        notifiable: [],
        alerts: [],
    },
    getters: {
        alert_rules: (state) => {
            return state.alert_rules;
        },
        alert_rule_by_id: (state) => (id) => {
            return state.alert_rules.find((item) => item.id === id);
        },
        notifiable: (state) => {
            return state.notifiable;
        },
        alerts: (state) => {
            return state.alerts;
        },
    },
    mutations: {
        clear: (state) => {
            state.alert_rules = [];
        },
        set_notifiable: (state, items) => {
            state.notifiable = items;
        },
        update: (state, item) => {
            const index = state.alert_rules.findIndex((a) => a.id === item.id);
            if (index !== -1) {
                state.alert_rules.splice(index, 1, item);
            } else {
                state.alert_rules.push(item);
            }
        },
        delete: (state, id) => {
            const index = state.alert_rules.findIndex((a) => a.id === id);
            if (index !== -1) {
                state.alert_rules.splice(index, 1);
            }
        }
    },
    actions: {
        storeOrUpdate: ({ commit, rootGetters }, payload) => {
            let typeName = payload.create
                ? 'storeAlertRule'
                : 'updateAlertRule';

            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .mutate({
                        mutation: payload.create
                            ? STORE_ALERT_RULE
                            : UPDATE_ALERT_RULE,
                        variables: {
                            id: payload.id,
                            projectId: payload.projectId,
                            input: payload.input,
                        },
                        fetchPolicy: 'no-cache',
                        notifyOnNetworkStatusChange: true,
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('update', data[typeName]);
                            resolve(data[typeName]);
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(error);
                    });
            });
        },
        subscribe: ({ commit, rootGetters }, payload) => {
            commit('clear');

            rootGetters.apollo.subscribe({
                query: SUBSCRIPTION_ALERT_RULE,
                variables: {
                    projectId: payload.project_id
                }
            }).subscribe({
                async next(message) {
                    const alert_rule = message.data.liveAlertRules;

                    if ( alert_rule.deleted_at && alert_rule.deleted_at !== '' ) {
                        commit('delete', alert_rule.id);
                    } else  {
                        commit('update', alert_rule);
                    }

                },
                error(error) {
                    console.error('error', error);
                }
            });
        },
        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .mutate({
                        mutation: DELETE_ALERT_RULE,
                        variables: {
                            id: payload.id,
                            projectId: payload.project_id,
                        },
                        fetchPolicy: 'no-cache',
                        notifyOnNetworkStatusChange: true,
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('delete', payload.id);
                            resolve(data.deleteAlertRule);
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(error);
                    });
            });
        },
        load_notifiable: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .query({
                        query: VIEW_NOTIFIABLE,
                        variables: {
                            projectId: payload.project_id,
                        },
                        fetchPolicy: 'no-cache',
                        notifyOnNetworkStatusChange: true,
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('set_notifiable', data.alertNotifiable);
                            resolve(data.alertNotifiable);
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(error);
                    });
            });
        }
    },
};
