import {
    CREATE_PUB_SUB_TOPIC,
    DELETE_PUB_SUB_TOPIC,
    SUBSCRIPTION_PUB_SUB_TOPICS,
    UPDATE_PUB_SUB_TOPIC,
} from '../../schema/topics';

export default {
    namespaced: true,
    state: {
        pub_sub_topics: [],
    },
    getters: {
        pubSubTopics: (state) => {
            return state.pub_sub_topics;
        },
        getById: (state) => (id) => {
            return state.pub_sub_topics.find(item => item.id === id);
        },
    },
    mutations: {
        clear: (state) => {
            state.pub_sub_topics = [];
        },
        updatePubSubTopics: (state, pub_sub_topics) => {
            state.pub_sub_topics = pub_sub_topics;
        },
        updateById: (state, obj) => {
            const index = state.pub_sub_topics.findIndex(item => item.id === obj.id);
            if (index !== -1) {
                state.pub_sub_topics.splice(index, 1, obj);
            } else {
                state.pub_sub_topics.push(obj);
            }
        },
    },
    actions: {
        subscribe: ({ commit, rootGetters }) => {
            commit('clear');
            rootGetters.apollo.subscribe({
                query: SUBSCRIPTION_PUB_SUB_TOPICS,
                variables: {
                    projectId: rootGetters['businessUnit/project'].id,
                }
            }).subscribe({
                next(message) {
                    commit('updateById', message.data.livePubSubTopics);
                },
                error(error) {
                    console.error('error', error);
                },
            });
        },

        storeOrUpdate: ({ commit, rootGetters, dispatch }, payload) => {
            return new Promise((resolve, reject) =>{
                const type = payload.create ? 'storePubSubTopic' : 'updatePubSubTopic';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_PUB_SUB_TOPIC : UPDATE_PUB_SUB_TOPIC,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        projectId: rootGetters['businessUnit/project'].id,
                        input: payload.input,
                    },
                })
                    .then((response) => {
                        commit('updateById', response.data[type]);
                        dispatch('connectorsLinks/reset', null, { root: true });
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteTopic: ({ rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .mutate({
                        mutation: DELETE_PUB_SUB_TOPIC,
                        variables: {
                            projectId: payload.projectId,
                            id: payload.id,
                        },
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
};
