
import gql from 'graphql-tag';

const baseRegistryTopicFragment = gql`
    fragment baseRegistryTopicFragment on RegistryTopic {
        id
        pub_sub_topic_id
        name
        type
        sub_type_name
        configuration
    }
`;

const registryTopicFragment = gql`
    fragment registryTopicFragment on RegistryTopic {
        ...baseRegistryTopicFragment
        registry_id
        pub_sub_topic {
            name
            status
            inbound
        }
    }
    ${baseRegistryTopicFragment}
`;

const registryFragment = gql`
    fragment registryFragment on Registry {
        id
        project_id
        name
        cuid
        configuration
        provisioning_secret
        provisioning_end_at
        provisioning_start_at
        cloud_provider_id
        cloud_provider {
            name
            configuration
        }
        topics {
            ...baseRegistryTopicFragment
        }
        device_types {
            id
            asset_type_id
            name
            icon
            meta_data
            default_configuration
            pivot {
                provisioning_quantity
                device_count
            }
        }
        updated_at
        deleted_at
    } 
    ${baseRegistryTopicFragment}
`;

export const STORE_REGISTRY = gql`
    mutation storeRegistry($input: RegistryInput!) {
        storeRegistry(input: $input) {
            ...registryFragment
        }
    }
    ${registryFragment}
`;

export const UPDATE_REGISTRY = gql`
    mutation updateRegistry($registryId: Int64!, $input: RegistryInput!) {
        updateRegistry(registryId: $registryId, input: $input) {
            ...registryFragment
        }
    }
    ${registryFragment}
`;

export const SUBSCRIPTION_REGISTRY = gql`
    subscription liveRegistry($projectId: Int64!) {
        liveRegistry(projectId: $projectId) {
            ...registryFragment
        }
    }
    ${registryFragment}
`;

export const UPDATE_REGISTY_TOPIC = gql`
    mutation updateRegistryTopic($projectId: Int64!, $id: Int64!, $input: RegistryTopicInput!) {
        updateRegistryTopic(projectId: $projectId, id: $id, input: $input) {
            ...registryTopicFragment
        }
    }
    ${registryTopicFragment}
`;

export const CREATE_REGISTRY_TOPIC = gql`
    mutation storeRegistryTopic($projectId: Int64!, $registryId: Int64!, $input: RegistryTopicInput!) {
        storeRegistryTopic(projectId: $projectId, registryId: $registryId, input: $input) {
            ...registryTopicFragment
        }
    }
    ${registryTopicFragment}
`;

export const RESET_REGISTRY_TOPIC = gql`
    mutation resetRegistryTopic($projectId: Int64!, $registryId: Int64!){
        resetRegistryTopic(projectId: $projectId, registryId: $registryId){
            ...baseRegistryTopicFragment
        }
    }
    ${baseRegistryTopicFragment}
`;

export const DELETE_REGISTRY_TOPIC = gql`
    mutation deleteRegistryTopic($projectId: Int64!, $registryId: Int64!, $id: Int64!){
        deleteRegistryTopic(projectId: $projectId, registryId: $registryId, id: $id){
            ...baseRegistryTopicFragment
        }
    }
    ${baseRegistryTopicFragment}
`;
