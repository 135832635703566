import gql from 'graphql-tag';

const alertRuleFragment = gql`
    fragment alertRuleFragment on AlertRule {
        id
        name
        description
        project_id
        registry_id
        is_active
        alert_count
        alert_messages {
            message
            language
        }
        conditions {
            id
            name
            operator
            state
            value
            hysteresis
        }
        notifiers {
            notifiable_type
            notifiable
        }
        updated_at
        deleted_at
    }
`;

const alertFragment = gql`
    fragment alertFragment on Alert {
        id
        rule_id
        device_id
        created_at
        stopped_at
    }
`;

const conditionsTriggeringFragment = gql`
    fragment conditionsTriggeringFragment on ConditionTriggering {
        is_triggering
        threshold_value
        state_value
        state_key
        operator
        threshold_hysteresis
        created_at
    }
`;

export const VIEW_NOTIFIABLE = gql`
    query getNotifiable($projectId: Int64!) {
        alertNotifiable(projectId: $projectId) {
            roles
            users {
                id
                name
                email
            }
        }
    }
`;

export const SUBSCRIPTION_ALERT_RULE = gql`
    subscription liveAlertRules($projectId: Int64!) {
        liveAlertRules(projectId: $projectId) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const STORE_ALERT_RULE = gql`
    mutation storeAlertRule($projectId: Int64!, $input: AlertRuleInput!) {
        storeAlertRule(projectId: $projectId, input: $input) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const UPDATE_ALERT_RULE = gql`
    mutation updateAlertRule(
        $projectId: Int64!
        $id: Int64!
        $input: AlertRuleUpdateInput!
    ) {
        updateAlertRule(projectId: $projectId, id: $id, input: $input) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const DELETE_ALERT_RULE = gql`
    mutation deleteAlertRule($projectId: Int64!, $id: Int64!) {
        deleteAlertRule(projectId: $projectId, id: $id) {
            ...alertRuleFragment
        }
    }
    ${alertRuleFragment}
`;

export const SUBSCRIPTION_ALERT = gql`
    subscription liveAlerts($projectId: Int64!) {
        liveAlerts(projectId: $projectId) {
            ...alertFragment
            conditions_triggering {
                ...conditionsTriggeringFragment
            }
        }
    }
    ${alertFragment}, ${conditionsTriggeringFragment}
`;
